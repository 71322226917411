import { AgencyPlaceholder } from 'src/components/AgencyPlaceholder';

import type { AGENCY_TYPE } from 'src/constants/real-estate';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import css from './styles.module.scss';

interface AgencyCardLogoProps {
  agencyType: AGENCY_TYPE;
  imageAlt: string;
  imageUrls?: string;
}

export function AgencyCardLogo({
  agencyType,
  imageAlt,
  imageUrls,
}: AgencyCardLogoProps) {
  return (
    <PepitaFigure ratio="custom" customClass={css['in-agencyCardLogo']}>
      {imageUrls ? (
        <PepitaFigure.Image
          src={imageUrls}
          customClass={css['in-agencyCardLogo__image']}
          alt={imageAlt}
          height={56}
          width={211}
          lazyLoad
        />
      ) : (
        <PepitaFigure.Content>
          <AgencyPlaceholder agencyType={agencyType} bordered />
        </PepitaFigure.Content>
      )}
    </PepitaFigure>
  );
}
