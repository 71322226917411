import { clsx } from 'clsx';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';

import type { AgencyCardProps } from '../AgencyCard';
import { AgencyCard } from '../AgencyCard';

import css from './styles.module.scss';
interface AgencyCardWithCoverProps extends AgencyCardProps {
  ratio?: 'wide' | 'standard' | 'square' | 'custom';
}

export function AgencyCardWithCover({
  agency,
  customClass,
  children,
  ratio,
}: AgencyCardWithCoverProps) {
  return (
    <div className={clsx(css['in-agencyCardWithCover'], customClass)}>
      <PepitaFigure ratio={ratio} cover>
        {agency.bannerImage ? (
          <PepitaFigure.Image
            src={agency.bannerImage}
            customClass={css['in-agencyCardWithCover__image']}
            lazyLoad
          />
        ) : (
          <PepitaFigure.Placeholder />
        )}
      </PepitaFigure>

      <AgencyCard agency={agency} hasCover>
        {children}
      </AgencyCard>
    </div>
  );
}
